import { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { useForm } from "react-hook-form";
import Dompurify from "dompurify";
import { useSnackbar } from "notistack";

import {
  useCompanyHomePageDetails,
  useCompanyInfo,
  useUpdateHomePageDetails,
} from "../../../hooks/company.hooks";
import { DEFAULT_BROKER_DETAILS } from "../../../commons/enum";
import sample from "./sample.json";
import "./privacy-and-policy.scss";
import CustomButton, {
  CustomOutlineButton,
} from "../../../components/custom-button";
import CustomModal from "../../../components/CustomModal";

const PrivacyAndPolicy = () => {
  const { data: companyInfo } = useCompanyInfo();
  const { id: companyId } = companyInfo;
  const companyHomePageDetails = useCompanyHomePageDetails();
  const [showButtons, setShowButtons] = useState(false);
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false);

  const { handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      policyAndPrivacyDesign: null,
    },
    values: companyHomePageDetails,
  });

  const privacyAndPolicyEditorRef = useRef(null);

  const updateHomePageDetails = useUpdateHomePageDetails();
  const { enqueueSnackbar } = useSnackbar();

  const { adminDetails = {}, businessName = "", name = "" } = companyInfo;
  const { user = {} } = adminDetails;
  const { firstName = "", lastName = "" } = user;

  const displayBusinessName =
    businessName || name || DEFAULT_BROKER_DETAILS.companyName;

  const displayCompanyName =
    name || businessName || DEFAULT_BROKER_DETAILS.companyName;

  const fullName =
    firstName || lastName
      ? `${firstName} ${lastName}`
      : DEFAULT_BROKER_DETAILS.fullName;

  const phoneNumber =
    companyInfo?.businessPhoneNumber ||
    user?.phoneNumber ||
    DEFAULT_BROKER_DETAILS.phoneNumber;

  const email =
    companyInfo?.customerSupportEmail ||
    user?.email ||
    DEFAULT_BROKER_DETAILS.supportEmail;

  const onLoad = (unlayer) => {
    if (companyHomePageDetails?.policyAndPrivacyDesign) {
      unlayer.loadDesign(
        JSON.parse(companyHomePageDetails.policyAndPrivacyDesign)
      );
    } else {
      unlayer.loadDesign(sample);
    }
  };

  const onReady = () => {
    setShowButtons(true);
  };

  const onSubmit = () => {
    const unlayer = privacyAndPolicyEditorRef.current?.editor;

    unlayer?.exportHtml(({ design, html }) => {
      setValue("policyAndPrivacyDesign", design);
      setValue(
        "policyAndPrivacyHtml",
        Dompurify.sanitize(html, { USE_PROFILES: { html: true } })
      );
      const data = getValues();
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (
          key.includes("rate") ||
          key.includes("reviews") ||
          key.includes("processes") ||
          key.includes("services") ||
          key.includes("policyAndPrivacyDesign")
        ) {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      });
      updateHomePageDetails.mutate({ id: companyId, params: formData });
    });
  };

  const handleResetDesign = () => {
    const unlayer = privacyAndPolicyEditorRef.current?.editor;
    unlayer.loadDesign(sample);
    setShowDeleteConfirmationModal(false);
    enqueueSnackbar("Privacy and policy design has been reset to default", {
      variant: "success",
    });
  };

  useEffect(() => {
    const unlayer = privacyAndPolicyEditorRef.current?.editor;
    if (!unlayer) {
      return;
    }
    if (companyHomePageDetails?.policyAndPrivacyDesign) {
      unlayer.loadDesign(
        JSON.parse(companyHomePageDetails.policyAndPrivacyDesign)
      );
    } else {
      unlayer.loadDesign(sample);
    }
  }, [companyHomePageDetails]);

  return (
    <form className="privacy-and-policy-form" onSubmit={handleSubmit(onSubmit)}>
      <div id="privacy-editor-container">
        <EmailEditor
          ref={privacyAndPolicyEditorRef}
          onLoad={onLoad}
          onReady={onReady}
          minHeight="700px"
          options={{
            id: "privacy-editor-container",
            version: "latest",
            appearance: {
              theme: "modern_light",
            },
            displayMode: "web",
            tools: {
              image: {
                enabled: false,
              },
              social: {
                enabled: false,
              },
              menu: {
                enabled: false,
              },
              carousel: {
                enabled: false,
              },
            },
            features: {
              smartMergeTags: true,
            },
            mergeTags: {
              business_name: {
                name: "Business Name",
                value: "{{business_name}}",
                sample: displayBusinessName,
              },
              company_name: {
                name: "Company Name",
                value: "{{company_name}}",
                sample: displayCompanyName,
              },
              full_name: {
                name: "Full Name",
                value: "{{full_name}}",
                sample: fullName,
              },
              phone_number: {
                name: "Phone Number",
                value: "{{phone_number}}",
                sample: phoneNumber,
              },
              email: {
                name: "Email",
                value: "{{email}}",
                sample: email,
              },
            },
          }}
        />
      </div>
      {showButtons && (
        <div className="privacy-and-policy-buttons">
          <CustomOutlineButton
            label="Save"
            buttonType="secondary"
            onClick={handleSubmit(onSubmit)}
          />
          <CustomButton
            label="Reset to default"
            onClick={() => setShowDeleteConfirmationModal(true)}
          />
        </div>
      )}
      <CustomModal
        title="Are you sure you want to reset to default?"
        isOpen={showDeleteConfirmationModal}
        handleCloseModal={() => setShowDeleteConfirmationModal(false)}
      >
        <div className="privacy-and-policy-modal-buttons">
          <CustomButton label="Reset to default" onClick={handleResetDesign} />
          <CustomOutlineButton
            label="Cancel"
            buttonType="secondary"
            onClick={() => setShowDeleteConfirmationModal(false)}
          />
        </div>
      </CustomModal>
    </form>
  );
};

export default PrivacyAndPolicy;
