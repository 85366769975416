import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import { useState } from "react";
import HeaderComponent from "../components/HeaderComponent";
import CompanySetting from "../features/account/company-setting";
import HomePageSegment from "../features/web-pages/home-page";
import { useTabStyles } from "../utils/styles-util";
import PrivacyAndPolicy from "../features/web-pages/privacy-and-policy";
import { useCompanyInfo } from "../hooks/company.hooks";

const useStyles = makeStyles({
  black: {
    color: "#000000",
  },
  unselect: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BASE_PATH = "web-pages";

const tabItems = [
  {
    key: "home-page",
    label: "Home Page",
    disableWhenNoHomePage: false,
  },
  {
    key: "privacy-and-policy",
    label: "Privacy & Policy",
    disableWhenNoHomePage: true,
  },
  {
    key: "credit-guide",
    label: "Credit Guide",
    disableWhenNoHomePage: true,
  },
  {
    key: "team-page",
    label: "Team Page",
    disableWhenNoHomePage: true,
  },
  // {
  //   key: "digital-fact-find",
  //   label: "Digital Fact Find",
  //   disableWhenNoHomePage: true,
  // },
  // {
  //   key: "meta-data-tracking-codes",
  //   label: "Meta Data & Tracking Codes",
  //   disableWhenNoHomePage: true,
  // },
  {
    key: "company-setting",
    label: "Company Setting",
    disableWhenNoHomePage: false,
  },
];

export default function ClientSegment({ location }) {
  const classes = useStyles();
  const tabsStyles = useTabStyles();
  const [currentTab, setCurrentTab] = useState(
    tabItems.find((item) => location.pathname.includes(item.key))?.key ??
      tabItems[0].key
  );
  const {
    data: { homepageDetailsId },
  } = useCompanyInfo();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue === "home-page") {
      navigate(`${BASE_PATH}`);
      return;
    }
    navigate(`${BASE_PATH}/${newValue}`);
  };

  return (
    <div className="sharing-referral-page account-page">
      <HeaderComponent
        headerContent={
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="secondary"
            textColor="inherit"
            classes={{
              root: tabsStyles.tabs,
              flexContainer: tabsStyles.tabsFlexContainer,
              indicator: tabsStyles.indicator,
            }}
          >
            {tabItems.map((tab) => (
              <Tab
                key={tab.key}
                label={tab.label}
                value={tab.key}
                disabled={tab.disableWhenNoHomePage && !homepageDetailsId}
                {...a11yProps(tab.key)}
                classes={{
                  textColorInherit:
                    currentTab === tab.key ? classes.black : classes.unselect,
                }}
              />
            ))}
          </Tabs>
        }
      />
      <div className="account-page__main-container">
        <Router basepath={BASE_PATH}>
          <HomePageSegment path="/" />
          <PrivacyAndPolicy path="/privacy-and-policy" />
          <CompanySetting path="/company-setting" />
        </Router>
      </div>
    </div>
  );
}
